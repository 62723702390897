<template>
  <div class="financialEstimateRule-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item
          v-if="false"
          >
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="false"
          >
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="dataList"
      border
      default-expand-all
      :height="tableHeight"
      header-cell-class-name="bg-info text-light"
      :show-summary="true"
      :summary-method="getSummaries"
      v-if="financialEstimateList.length && dataList.length"
      >
      <el-table-column fixed="left" type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="MeterageDisplayCode" width="100" header-align="center" align="center" label="子目号"></el-table-column>
      <el-table-column fixed="left" prop="MeterageName" width="250" header-align="center" align="center" label="子目名称"></el-table-column>
      <el-table-column fixed="left" prop="Unit" width="70" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column fixed="left" prop="Num" width="120" header-align="center" align="center" label="合同数量"></el-table-column>
      <el-table-column fixed="left" prop="Price" width="120" header-align="center" align="center" label="合同单价"></el-table-column>
      <el-table-column fixed="left" prop="PictureAmount" width="120" header-align="center" align="center" label="合同金额"></el-table-column>
      <el-table-column
        v-for="item in financialEstimateList"
        :key="item.financialEstimateGuid"
        :label="item.financialEstimateName"
        header-align="center"
        align="center"
        :prop="`${item.financialEstimateCode}J`"
        width="220"
        >
        <el-table-column
          v-for="childItem in item.children"
          :key="childItem.financialEstimateGuid"
          :label="childItem.financialEstimateName"
          :prop="`${childItem.financialEstimateCode}J`"
          header-align="center"
          align="center"
          width="150"
          >
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import FinancialEstimateRuleModel from '@/model/FinancialEstimateRuleModel'
import financialEstimateRuleService from '@/services/financialEstimateRuleService'
import financialEstimateService from '@/services/financialEstimateService'
import PageOperation from '@/mixins/PageOperation'
import GetProject from '@/mixins/GetProject'
import PageInfo from '@/support/PageInfo'
import auth from '@/common/auth'

export default {
  name: 'FinancialEstimateRuleIndex',
  mixins: [
    GetProject,
    PageOperation
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      total: 0,
      dataList: [],
      meterageList: [],
      financialEstimateList: [],
      financialEstimateRuleList: [],
      financialEstimateRuleModel: new FinancialEstimateRuleModel(),
      searchModel: {
        financialEstimateRuleName: '',
        projectGuid: '',
        companyGuid: ''
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    }
  },
  methods: {
    handleSearchClick () {
      this.getEstimateList()
    },
    handleAddClick () {
      this.$router.push('/financialEstimateRuleForm')
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _financialEstimateRuleModel = new FinancialEstimateRuleModel(row)
          financialEstimateRuleService.delete(_financialEstimateRuleModel.resourceId)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getFinancialEstimateRuleList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    _getFinancialEstimateRuleList () {
      financialEstimateRuleService.list(new PageInfo({}), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.financialEstimateRuleList = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _getFinancialEstimateList () {
      financialEstimateService.list({}, { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          this.financialEstimateList = res.data.data
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    getEstimateList () {
      this.$myLoading()
      financialEstimateRuleService.getTenderEstimates(auth.getUserInfo().projectGuid, auth.getUserInfo().tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.meterageList = res.data.data
            this.dataList = this.meterageList
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          }
          this.$loading().close()
        })
        .catch(err => {
          console.log(err)
          this.$loading().close()
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value)) && index > 5) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = ''
        }
      })

      return sums
    }
  },
  created () {
    this._getFinancialEstimateList()
    this._getFinancialEstimateRuleList()
    this.refreshMethod = this._getFinancialEstimateRuleList
    this.getEstimateList()
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
